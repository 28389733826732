import _ from "lodash";
import { IDisease, IDiseaseFactor, IDiseaseScored, ISymptom, SymptomType } from "../types";
import { calc } from "./utils";

interface IProps {
  symptoms: ISymptom[];
  diseases: IDisease[];
  silent?: boolean;
}

export const epsilon = 0.01; // NOTICE lower number may cause NaN issue
const inactive = epsilon; // any fucking number - no matter
const getRate = (rate: number) => (rate === -1 ? epsilon : rate);

const getSymptomProbablity = (factor: IDiseaseFactor, symptoms: ISymptom[], silent?: boolean) => {
  // This will check if ranges-array exists and sort them by their rate
  const dfranges = factor.ranges ? _.orderBy(factor.ranges, (a) => getRate(a.rate), "desc") : null; // dfranges = disease factor ranges

  for (const symptom of symptoms) {
    if (symptom.id === factor.sid) {
      switch (symptom.type) {
        case SymptomType.Range:
          if (!Array.isArray(dfranges)) return 1;
          for (const range of dfranges) {
            if (
              typeof symptom.value !== "object" ||
              // Array.isArray(symptom.value) ||
              symptom.value instanceof Date ||
              !symptom.value ||
              !symptom.value.a ||
              !symptom.value.b
            ) {
              if (!silent) {
                console.error(
                  `Type mismatch for symptom.value ${symptom.id} expected range but got ${typeof symptom.value}`,
                  symptom.value
                );
              }
              return inactive;
            }
            if (symptom.value.a >= range.a && symptom.value.b <= range.b) {
              return getRate(range.rate);
            }
          }
          return inactive; // TODO
        case SymptomType.Number:
          if (!Array.isArray(dfranges)) return 1;
          for (const range of dfranges) {
            if ((symptom.value! as number) >= range.a && (symptom.value! as number) <= range.b) {
              return getRate(range.rate);
            }
          }
          return inactive;
        default:
          if (symptom.value) return getRate(factor.rate!);
          return inactive;
      }
    }
  }

  if (!silent) console.error("Couldn't find factor", factor.sid);
  return 1;
};

const FIX_FRAC = 100;
// const FIX_FRAC = 1;

// nominator
const getDiseaseProbablity = (disease: IDisease, symptoms: ISymptom[], silent?: boolean): number => {
  if (!silent) console.groupCollapsed("Disease", disease.name);
  const mul = disease.factors.reduce((v, factor) => v * getSymptomProbablity(factor, symptoms, silent) * FIX_FRAC, 1);
  if (!silent) console.groupEnd();
  return mul; // P(Di) * ∏j{P(Sj|Di)}
};

const getDiseaseProbablity_FIX_FRAC = (disease: IDisease, symptoms: ISymptom[], silent?: boolean): number =>
  getDiseaseProbablity(disease, symptoms, silent) * FIX_FRAC;

export default function getScores({ diseases, symptoms, silent }: IProps): IDiseaseScored[] {
  const nominators: number[] = diseases.map((disease) => getDiseaseProbablity_FIX_FRAC(disease, symptoms, silent));
  const dinaminator = nominators.reduce((a, b) => calc(a + b), 0); // Σi{P(Di)} * ∏j{P(Sj|Di)}
  const pnominators: number[] = diseases.map((disease) =>
    calc(disease.preval * getDiseaseProbablity_FIX_FRAC(disease, symptoms, silent))
  );
  const pdinaminator = pnominators.reduce((a, b) => calc(a + b), 0); // Σi{P(Di)} * ∏j{P(Sj|Di)}
  if (!silent) console.log({ nominators, dinaminator, pnominators, pdinaminator });
  return diseases.map((disease, i) => ({
    ...disease,
    value: calc(nominators[i] / dinaminator),
    pvalue: calc(pnominators[i] / pdinaminator),
  }));
}
