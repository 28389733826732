import { Feature, ISymptomRaw, SymptomType } from "../types";

const symptoms: ISymptomRaw[] = [
  {
    id: "pat-info",
    name: "Information",
    page: 1,
    options: ["pat-name", "pat-gender", "pat-age", "pat-images"],
  },
  {
    id: "pat-name",
    name: "Full Name",
    desc: { feature: Feature.DuplicateNameChecker },
    required: true,
    type: SymptomType.String,
    open: true,
    omitHash: true,
    gpt: false,
    details: "patient name",
  },
  {
    id: "pat-age",
    name: "Age",
    required: true,
    type: SymptomType.Number,
    open: true,
    details: "patient age",
  },
  {
    id: "pat-gender",
    name: "Gender",
    required: true,
    type: SymptomType.Enum,
    options: ["pat-male", "pat-female"],
    open: true,
  },
  {
    id: "pat-male",
    name: "Male",
    details: "patient gender = male",
  },
  {
    id: "pat-female",
    name: "Female",
    details: "patient gender = female",
  },
  {
    id: "pat-images",
    name: "Panaromic Images",
    desc: { feature: Feature.ImagePicker },
    type: SymptomType.String,
    required: true,
    open: true,
    noInput: true,
    gpt: false,
  },
  {
    id: "chief-comp",
    name: "Chief complaint",
    page: 2,
    options: ["pain-0", "fever-illness", "paresth-anes", "bleeding", "purulent", "swelling", "trismus"],
  },
  {
    id: "fever-illness",
    name: "Fever and Illness",
    details: "fever or sickness or illness",
  },
  {
    id: "paresth-anes",
    name: "Paresthesia and anesthesia",
    details: "parasthesia or anesthesia of skin",
  },
  {
    id: "bleeding",
    name: "Gingival bleeding",
    details: "gingiva bleeding",
  },
  {
    id: "purulent",
    name: "Purulent (pus) drainage",
    details: "pus drainage",
  },
  {
    id: "swelling",
    name: "Swelling",
    details: "swelling or asymmetry of face",
  },
  {
    id: "pain-0",
    name: "Pain",
    details: "pain (facial or tooth or general pain)",
  },
  {
    id: "trismus",
    name: "Trismus",
    details: "muscle trismus or limitation of mouth opening",
  },
  {
    id: "pat-his-cli-fin",
    name: "History & Clinical findings",
    desc: { title: "Patient's history \nand Clinical findings" },
    page: 3,
    options: ["onset-course", "history-of-surgery", "hist-radio", "lymph", "impact", "sig-blood"],
  },
  {
    id: "lymph",
    name: "Lymph nodes involvement",
    details: "lymph nodes growth or involvement",
  },
  {
    id: "impact",
    name: "Impacted, unerupted or supernumerary tooth",
    details: "impacted or unerupted tooth at lesion site",
  },
  {
    id: "history-of-surgery",
    name: "History of surgery, trauma or tooth extraction",
    details: "history of surgery, trauma or tooth extraction",
  },
  {
    id: "sig-blood",
    name: "Significant blood's values change",
    options: ["calcium", "phosphorus", "alkaline"],
  },
  {
    id: "calcium",
    name: "Calcium",
    type: SymptomType.Enum,
    options: ["increase-1", "decrease-1"],
  },
  {
    id: "increase-1",
    name: "Increase",
    gpt: false,
  },
  {
    id: "decrease-1",
    name: "Decrease",
    gpt: false,
  },
  {
    id: "phosphorus",
    name: "Phosphorus",
    type: SymptomType.Enum,
    options: ["increase-2", "decrease-2"],
  },
  {
    id: "increase-2",
    name: "Increase",
    gpt: false,
  },
  {
    id: "decrease-2",
    name: "Decrease",
    gpt: false,
  },
  {
    id: "alkaline",
    name: "Alkaline phosphatase",
    type: SymptomType.Enum,
    options: ["increase-3", "decrease-3"],
  },
  {
    id: "increase-3",
    name: "Increase",
    gpt: false,
  },
  {
    id: "decrease-3",
    name: "Decrease",
    gpt: false,
  },
  {
    id: "hist-radio",
    name: "History of radiotherapy and chemotherapy",
    details: "history of radiotherapy and chemotherapy",
  },
  {
    id: "onset-course",
    name: "Onset and course",
    type: SymptomType.Enum,
    options: ["slow-0", "moderate-0", "rapid-0"],
  },
  {
    id: "slow-0",
    name: "Slow (Months to years)",
    details: "slow growth of lesion (lesion has appeared or existed for months or even years)",
  },
  {
    id: "moderate-0",
    name: "Moderate (weeks to about 2months)",
    details: "moderate growth of lesion (lesion has appeared or existed less than two months but more than a week)",
  },
  {
    id: "rapid-0",
    name: "Rapid (Hours to days)",
    details: "rapid growth of lesion (lesion has appeared or existed within afew hours or days )",
  },
  {
    id: "clinical-exam",
    name: "Clinical examination",
    desc: { title: "Observable Findings and Clinical examination" },
    page: 4,
    options: ["appearance", "consist", "tooth-exam", "aspiration", "auscultation"],
  },
  {
    id: "appearance",
    name: "Appearance",
    options: ["soft-tissue", "hemorrhage"],
  },
  {
    id: "hemorrhage",
    name: "Hemorrhage",
    details: "hemorrheage or bleeding from lesion site or soft tissue lesion",
  },
  {
    id: "soft-tissue",
    name: "Soft tissue lesion",
    details: "soft tissue lesion",
  },
  {
    id: "tooth-exam",
    name: "Tooth examination",
    options: ["vitality", "percussion", "mobile-teeth"],
  },
  {
    id: "vitality",
    name: "Tooth vitality",
    type: SymptomType.Enum,
    options: ["nonvital"],
  },
  {
    id: "nonvital",
    name: "Nonvital tooth",
    details: "nonvital tooth or negative tooth vitality test",
  },
  {
    id: "percussion",
    name: "Percussion",
    type: SymptomType.Enum,
    options: ["pain-1", "pump"],
  },
  {
    id: "mobile-teeth",
    name: "Tooth mobility",
    details: "tooth has mobility",
  },
  {
    id: "pain-1",
    name: "Pain",
    details: "tooth is sensetive to percussion or tooth has pain on percussion",
  },
  {
    id: "pump",
    name: "Pumping tooth",
    details: "pumping tooth or tooth pumps-up on percussion or pressure",
  },
  {
    id: "aspiration",
    name: "Aspiration",
    type: SymptomType.Enum,
    options: ["negative", "positive"],
  },
  {
    id: "negative",
    name: "Negative",
    details: "negative aspiration",
  },
  {
    id: "positive",
    name: "Positive",
    type: SymptomType.Enum,
    options: ["blood", "serosanguinous", "serum", "cheesy-mat", "pus"],
  },
  {
    id: "blood",
    name: "Blood",
    details: "posetive blood aspiration",
  },
  {
    id: "serosanguinous",
    name: "Serosanguinous",
    details: "posetive serum and blood (serosanguinous) aspiration",
  },
  {
    id: "serum",
    name: "Serum like fluid",
    details: "posetive serum aspiration",
  },
  {
    id: "cheesy-mat",
    name: "Cheesy material",
    details: "posetive cheesy material aspiration",
  },
  {
    id: "pus",
    name: "Pus",
    details: "posetive pus aspiration",
  },
  {
    id: "auscultation",
    name: "Auscultation",
    details: "posetive ausculation test or lesion has throbbing sound",
  },
  {
    id: "consist",
    name: "Consistancy",
    type: SymptomType.Enum,
    options: ["soft", "rubbery", "firm", "bony-hard"],
  },
  {
    id: "soft",
    name: "Soft",
    details: "lesion has soft consistancy on palpation",
  },
  {
    id: "rubbery",
    name: "Rubbery",
    details: "lesion has rubbery consistancy on palpation",
  },
  {
    id: "firm",
    name: "Firm",
    details: "lesion has firm consistancy on palpation",
  },
  {
    id: "bony-hard",
    name: "Bony-hard",
    details: "lesion has bony-hard consistancy on palpation",
  },
  {
    id: "imaging-fin",
    name: "Imaging finding",
    page: 5,
    options: ["location", "shape-size", "periphery", "type", "int-struct", "effects-surr"],
  },
  {
    id: "type",
    name: "Type",
    type: SymptomType.Enum,
    options: ["solitary", "multiple-separate", "diffuse"],
    required: true,
  },
  {
    id: "shape-size",
    name: "Shape and size",
    options: ["size", "shape"],
  },
  {
    id: "size",
    name: "Size",
    type: SymptomType.Number,
  },
  {
    id: "shape",
    name: "Shape",
    type: SymptomType.Enum,
    options: ["round", "scalloped", "irregular"],
    required: true,
  },
  {
    id: "round",
    name: "Round, Ovoid or Regular",
    details: "lesion has round shape",
  },
  {
    id: "scalloped",
    name: "Scalloped",
    details: "lesion has scalloped shape or border",
  },
  {
    id: "irregular",
    name: "Irregular",
    details: "lesion has irregular shape",
  },
  {
    id: "periphery",
    name: "Periphery",
    required: true,
    type: SymptomType.Enum,
    options: ["well-defined", "ill-defined"],
  },
  {
    id: "well-defined",
    name: "Well-defined",
    type: SymptomType.Enum,
    open: true,
    options: ["non-corticated", "corticated", "sclerotic", "soft-capsule"],
  },
  {
    id: "non-corticated",
    name: "Non corticated",
    details: "lesion has well-defined and non-corticated border",
  },
  {
    id: "corticated",
    name: "Corticated",
    details: "lesion has well-defined and corticated border",
  },
  {
    id: "sclerotic",
    name: "Sclerotic",
    details: "lesion has well-defined and sclerotic border",
  },
  {
    id: "soft-capsule",
    name: "Radiolucent rim (Soft capsule)",
    details: "lesion has well-defined border with radiolucent rim (soft capsule)",
  },
  {
    id: "ill-defined",
    name: "Ill-defined",
    type: SymptomType.Enum,
    options: ["blending", "invasive"],
  },
  {
    id: "blending",
    name: "Blending border",
    details: "lesion has ill-defined and blending border",
  },
  {
    id: "invasive",
    name: "Invasive and Destructive border",
    details: "lesion has ill-defined and invasive border",
  },
  {
    id: "location",
    name: "Location",
    desc: {
      title: "Pathologic extension",
      feature: Feature.DentPicker,
    },
    value: true,
    required: true,
    options: ["ana-location", "side", "relation-tooth"],
  },
  {
    id: "ana-location",
    name: "Anatomic Location",
    type: SymptomType.Enum,
    options: ["maxilla", "mandible", "both"],
  },
  {
    id: "maxilla",
    name: "Maxilla",
    type: SymptomType.Range,
    min: 1,
    max: 12,
    details:
      "Maxilla: tuberosity (from 3 to 4) - third molar or tooth number 8 (from 4 to 5) - second molar or tooth number 7 (from 5 to 6) - first molar or tooth number 6 (from 6 to 7) - molars region (from 4 to 7) - second premolar or tooth number 5 (from 7 to 8) - first premolar or tooth number 4 (from 8 to 9) - canine or tooth number 3 (from 9 to 10) - lateral or tooth number 2 (from 10 to 11) - central or tooth number 1 (from 11 to 12) - incisors (from 10 to 12)",
  },
  {
    id: "mandible",
    name: "Mandible",
    type: SymptomType.Range,
    min: 1,
    max: 12,
    details:
      "Mandible: condyle (from 1 to 2) - beginning of ramus (from 3 to 4) - ramus (from 2 to 4) - third molar or tooth number 8 (from 4 to 5) - second molar or tooth number 7 (from 5 to 6) - first molar or tooth number 6 (from 6 to 7) - molars region (from 4 to 7) - second premolar or tooth number 5 (from 7 to 8) - first premolar or tooth number 4 (from 8 to 9) - canine or tooth number 3 (from 9 to 10) - lateral or tooth number 2 (from 10 to 11) - central or tooth number 1 (from 11 to 12) - incisors (from 10 to 12)",
  },
  {
    id: "both",
    name: "Both",
    type: SymptomType.Range,
    min: 1,
    max: 12,
    details:
      "On both regions (maxilla and mandible) selected: condyle (from 1 to 2) - beginning of ramus (from 3 to 4) - ramus (from 2 to 4) - third molar or tooth number 8 (from 4 to 5) - second molar or tooth number 7 (from 5 to 6) - first molar or tooth number 6 (from 6 to 7) - molars region (from 4 to 7) - second premolar or tooth number 5 (from 7 to 8) - first premolar or tooth number 4 (from 8 to 9) - canine or tooth number 3 (from 9 to 10) - lateral or tooth number 2 (from 10 to 11) - central or tooth number 1 (from 11 to 12) - incisors (from 10 to 12)",
  },
  {
    id: "side",
    name: "Side",
    type: SymptomType.Enum,
    options: ["unilateral", "bilateral"],
    required: true,
  },
  {
    id: "unilateral",
    name: "Unilateral",
    type: SymptomType.Enum,
    options: ["unilateral-right", "unilateral-left"],
    open: true,
  },
  {
    id: "unilateral-right",
    name: "Right",
    details: "Unilateral lesion at right side of mandible or maxilla",
  },
  {
    id: "unilateral-left",
    name: "Left",
    details: "Unilateral lesion at left side of mandible or maxilla",
  },
  {
    id: "bilateral",
    name: "Bilateral",
    details: "Bilateral lesion at both sides of mandible or maxilla",
  },
  {
    id: "relation-tooth",
    name: "Epicenter of lesion",
    type: SymptomType.Enum,
    options: ["periapical", "pericoronal", "interradicular", "related"],
    required: true,
  },
  {
    id: "pericoronal",
    name: "Pericoronal (Coronal to CEJ)",
    details: "Epicenter of lesion is in pericoronal position to tooth (lesion is coronal to CEJ)",
  },
  {
    id: "interradicular",
    name: "Root (Interradicular, Midroot or whole root)",
    details: "Epicenter of lesion is around whole root or mid root or between roots",
  },
  {
    id: "periapical",
    name: "Periapical (Only apex area)",
    details: "Epicenter of lesion is around the apex of tooth (periapical)",
  },
  {
    id: "related",
    name: "Not related to tooth",
    details: "Epicenter of lesion is non-odontogenic",
  },
  {
    id: "solitary",
    name: "Solitary",
    details: "There is only one lesion or lesion type is solitary",
  },
  {
    id: "multiple-separate",
    name: "Multiple seprate",
    details: "There are multiple seperate lesion or lesion type is multipe seprate",
  },
  {
    id: "diffuse",
    name: "Generalized diffuse",
    gpt: false,
  },
  {
    id: "int-struct",
    name: "Internal structure",
    required: true,
    type: SymptomType.Enum,
    options: ["radiolucent", "mixed", "radiopaque"],
  },
  {
    id: "radiopaque",
    name: "Radiopaque",
    type: SymptomType.Enum,
    options: ["radiopaue-zone", "radiopaque-nozone"],
  },
  {
    id: "radiopaue-zone",
    name: "With radiolucent zone",
    details: "radiopaque internal strcuture with radioclucent zone within it (radiolucent rim)",
  },
  {
    id: "radiopaque-nozone",
    name: "No specific zone",
    details: "radiopaque or completely radiopaque internal structure",
  },
  {
    id: "radiolucent",
    name: "Radiolucent",
    type: SymptomType.Enum,
    open: true,
    options: ["unilocular", "multilocular", "rarefaction"],
  },
  {
    id: "unilocular",
    name: "Unilocular",
    type: SymptomType.Enum,
    open: false,
    options: ["uni1", "uni2"],
  },
  {
    id: "uni1",
    name: "Completely radiolucent",
    details: "completely radiolucent internal structure",
  },
  {
    id: "uni2",
    name: "Radiolucent with flecks",
    details: "radiolucent internal structure with flecks (radiopaque zone) within it",
  },
  {
    id: "multilocular",
    name: "Multilocular",
    type: SymptomType.Enum,
    open: false,
    options: ["linear", "curved"],
  },
  {
    id: "linear",
    name: "Curved and Linear septa",
    type: SymptomType.Enum,
    options: ["wispy1", "coarse1"],
  },
  {
    id: "wispy1",
    name: "Wispy or Thin",
    details: "multilocular internal structure with linear wispy septa",
  },
  {
    id: "coarse1",
    name: "Coarse or Thick",
    details: "multilocular internal structure with linear coarse septa",
  },
  {
    id: "curved",
    name: "Curved septa",
    type: SymptomType.Enum,
    options: ["wispy2", "coarse2"],
  },
  {
    id: "wispy2",
    name: "Wispy or Thin",
    details: "multilocular internal structure with curved wispy septa",
  },
  {
    id: "coarse2",
    name: "Coarse or Thick",
    details: "multilocular internal structure with curved coarse septa",
  },
  {
    id: "rarefaction",
    name: "Generalized rarefaction",
    gpt: false,
  },
  {
    id: "mixed",
    name: "Radiopaque and Radiolucent (Mixed)",
    details: "Radiopaque and Radiolucent (Mixed) internal structure",
  },
  {
    id: "effects-surr",
    name: "Effects on surrending",
    options: ["tooth", "dura", "wide-0", "ian-canal", "sinus", "corital-bone", "necrotic", "periosteal-reaction"],
  },
  {
    id: "tooth",
    name: "Tooth",
    options: ["displace-0", "resorp-1", "eruption"],
  },
  {
    id: "eruption",
    name: "Early (froced) eruption",
    details: "tooth forced eruption",
  },
  {
    id: "displace-0",
    name: "Displacement",
    details: "tooth or root displacement",
  },
  {
    id: "resorp-1",
    name: "Root resorption",
    details: "root resorption",
  },
  {
    id: "dura",
    name: "Loss of lamina dura (without root resorption)",
    details: "lamina dura is lost or destructed",
  },
  {
    id: "wide-0",
    name: "PDL space widening",
    details: "pdl space widening",
  },
  {
    id: "ian-canal",
    name: "IAN canal",
    options: ["displace-1", "enlarge", "destruct-0"],
  },
  {
    id: "displace-1",
    name: "Displacement",
    details: "IAN canal displacement",
  },
  {
    id: "enlarge",
    name: "Enlargment",
    details: "IAN canal enlargment",
  },
  {
    id: "destruct-0",
    name: "Destruction",
    details: "IAN canal destruction",
  },
  {
    id: "sinus",
    name: "Invagination to sinus space",
    details: "invagination of lesion to sinus space",
  },
  {
    id: "corital-bone",
    name: "Cortial bone (Jaws and maxillary sinus)",
    type: SymptomType.Enum,
    options: ["expand", "destruct-3", "extend"],
  },
  {
    id: "expand",
    name: "Expansion or displacement without destruction or perforation",
    details: "Only Expansion or displacement of cortical bone without destruction or perforation",
  },
  {
    id: "destruct-3",
    name: "Expansion or displacement with destruction or perforation",
    details: "Expansion and Destruction or perforation of cortical bone",
  },
  {
    id: "extend",
    name: "Extension within bone without expansion or destruction",
    details: "Extension within bone without expansion or destruction of cortical bone",
  },
  {
    id: "necrotic",
    name: "Necrotic bone",
    options: ["sequestrum", "fracture"],
  },
  {
    id: "sequestrum",
    name: "Sequestrum (Bone island)",
    details: "sequestrum (Bone island)",
  },
  {
    id: "fracture",
    name: "Pathologic fracture",
    details: "pathologic fracture",
  },
  {
    id: "periosteal-reaction",
    name: "Periosteal reaction",
    type: SymptomType.Enum,
    options: ["single", "onion", "solid", "spiculated", "compelx", "codman"],
  },
  {
    id: "single",
    name: "Single layer",
    details: "single layer periosteal reaction",
  },
  {
    id: "onion",
    name: "Mulitlayered(Onion skin)",
    details: "mulitlayered (onion skin) periosteal reaction",
  },
  {
    id: "solid",
    name: "Solid",
    gpt: false,
  },
  {
    id: "compelx",
    name: "Disorganized or Complex",
    gpt: false,
  },
  {
    id: "codman",
    name: "codman triangle",
    details: "codman triangle periosteal reaction",
  },
  {
    id: "spiculated",
    name: "Spiculated or Sunburst",
    details: "spiculated or sunburst periosteal reaction",
  },
] as const;

export default symptoms;
